<template>
  <div class="footer-container width100">
    <div class="footer-top flex justify-between">
      <div class="left-logo" @click="toHome">
        <img src="~@/assets/commonImages/footer-logo.png" alt="" />
        <div class="tel">029-85796557</div>
      </div>
      <div class="center-nav flex">
        <div
          class="nav-column flex flex-column items-start"
          v-for="(item, i) in navItems"
          :key="item.title"
        >
          <div class="nav-title">{{ item.title }}</div>
          <div class="nav-list flex flex-column items-start">
            <div
              class="nav-list-item"
              v-for="(subItem, j) in item.children"
              :key="subItem"
              @click="footerJump(i, j)"
            >
              {{ subItem }}
            </div>
          </div>
        </div>
      </div>
      <div class="right-QRCode flex flex-column items-center">
        <div class="small-logos flex">
          <img src="~@/assets/commonImages/qq.png" alt="" />
          <img src="~@/assets/commonImages/wx.png" alt="" />
          <img src="~@/assets/commonImages/wb.png" alt="" />
          <img src="~@/assets/commonImages/fb.png" alt="" />
          <img src="~@/assets/commonImages/tw.png" alt="" />
        </div>
        <img class="QRCode" src="~@/assets/commonImages/footer-QR.png" />
        <div class="description">【扫我关注】</div>
      </div>
    </div>

    <div class="footer-bot flex items-center">
      <div class="bot-item flex">
        <div class="add">
          地址：陕西省西安市雁塔区曲江街道雁翔路博源科技广场D座7F
        </div>
        <div class="email">邮箱：business@natuxtech.com</div>
      </div>

      <div class="bot-item number-click" @click="toNewPage">陕ICP备2020014264号-1</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navItems: [
        {
          title: "走进纳图",
          children: [
            "公司简介",
            "核心团队",
            "发展历程",
            "所获荣誉",
            "组织架构",
          ],
        },
        {
          title: "产品中心",
          children: [
            "环境监控系统",
            "能耗监控系统",
            "人员管理系统",
            "视频监控系统",
            "仓储管理系统",
            "采样管理系统",
          ],
        },
        {
          title: "解决方案",
          children: ["智慧实验室", "智慧工厂", "智慧养殖"],
        },
        {
          title: "客户案例",
          children: [
            "天隆科技智慧工厂",
            "远征实验室",
            "安奈苏州实验室",
            "正邦集团",
            "天博医学检验中心",
          ],
        },
        {
          title: "资讯中心",
          children: ["公司新闻", "行业动态", "媒体报道", "学术研究"],
        },
      ],
    };
  },

  methods: {
    toHome() {
      this.$router.push("/");
      this.toTop();
    },
    toTop() {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      // 清空上一次的定时器
      let timer = null;
      clearInterval(timer);
      // 创建定时器，每 15ms 执行一次箭头函数
      timer = setInterval(() => {
        // 每次执行函数，scrollTop 减少十分之一
        scrollTop -= scrollTop / 4;
        window.scrollTo(0, scrollTop);
        // 当 scrollTop 小于 2 时，直接使 scrollTop 为 0，并且清空定时器。
        if (scrollTop < 2) {
          window.scrollTo(0, 0);
          clearInterval(timer);
        }
      }, 15);
    },
    footerJump(i, j) {
      console.log(this.navItems[i], this.navItems[i].children[j]);
      switch (i) {
        case 0: {
          this.toTop();
          this.$router.push("/learnNatux?p=" + j);
          break;
        }
        case 1: {
          // document.documentElement.scrollTop = 0;
          this.toTop();
          this.$router.push("/product_center?product=" + j);
          break;
        }
        case 2: {
          if (j == 0) {
            this.toTop();
            this.$router.push("/solution_labo");
          }
          if (j == 1) {
            this.toTop();
            this.$router.push("/solution_factory");
          }
          if (j == 2) {
            this.toTop();
            this.$router.push("/solution_cultivation");
          }
          break;
        }
        case 3: {
          this.toTop();
          this.$router.push("/customerCase");
          break;
        }
        case 4: {
          this.toTop();
          this.$router.push("/contactUs");
          break;
        }

        default:
          break;
      }
    },

    toNewPage() {
      window.open("https://beian.miit.gov.cn/");
    },
  },
};
</script>

<style lang="scss" scoped>
@function vw($px) {
  @return ($px / 1920) * 100vw;
}

.footer-container {
  height: vw(392);
  // 中等屏幕
  @media screen and (max-width: 1200px) {
    height: vw(500);
  }
  background: #011428;
  transition: 0.5s ease all;
  .footer-top {
    height: vw(323);
    // 中等屏幕
    @media screen and (max-width: 1200px) {
      height: vw(431);
    }
    border-bottom: 1px solid #ffffff;
    padding: 0 vw(218);
    @media screen and (max-width: 800px) {
      padding: 0 vw(30);
    }
    .left-logo {
      cursor: pointer;
      img {
        width: vw(247);
        height: vw(78);
        margin-top: vw(88);
        margin-bottom: vw(23);
      }
      .tel {
        font-size: vw(30);
        color: #fff;
        font-weight: bold;
        letter-spacing: vw(5);
      }
    }
    .center-nav {
      margin-top: vw(70);

      .nav-column {
        margin-right: vw(68);
        &:last-child {
          margin-right: 0;
        }
        .nav-title {
          font-size: vw(14);
          font-weight: bold;
          color: #fff;
          margin-bottom: vw(30);
        }
        .nav-list {
          .nav-list-item {
            margin-bottom: vw(13);
            color: rgba(161, 173, 187, 1);
            font-size: vw(14);
            cursor: pointer;
            transition: 0.5s ease all;
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              color: rgba(255, 154, 79, 1);
              text-decoration: underline;
            }
          }
        }
      }
    }
    .right-QRCode {
      margin-top: vw(72);
      .small-logos {
        img {
          width: vw(18);
          height: vw(18);
          margin-right: vw(30);
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .QRCode {
        width: vw(100);
        height: vw(100);
        margin-bottom: vw(14);
        margin-top: vw(27);
      }
      .description {
        font-size: vw(12);
        color: #fff;
      }
    }
  }

  .footer-bot {
    height: vw(68);
    padding: 0 vw(218);
    .bot-item {
      color: #ffffff;
      font-size: vw(12);
      .add {
        margin-right: vw(30);
      }
    }
  }
}

.number-click{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-left: vw(30);
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
</style>
